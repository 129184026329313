import React, { Component } from 'react'
import $ from 'jquery';
import Nav from '../components/Nav';
import './Header.scss';


export class Header extends Component {
  componentDidMount() {
    var $nav = $('.component-navigation');
    var $toggle = $nav.next('.component-nav-icon').find('.js-nav-toggle');
    var $toggle_close = $nav.find('.js-nav-toggle');

    $toggle.on('click', function() {
        var isOpen = $nav.hasClass('slide-in');

        $nav.attr('class', isOpen ? 'component-navigation slide-out' : 'component-navigation slide-in');
    });  

    $toggle_close.on('click', function() {
        var isOpen = $nav.hasClass('slide-in');

        $nav.attr('class', isOpen ? 'component-navigation slide-out' : 'component-navigation slide-in');
    });
    
    $nav.find('nav').on('click', function() {
      var isOpen = $nav.hasClass('slide-in');
      $nav.attr('class', isOpen ? 'component-navigation slide-out' : 'component-navigation slide-in');
    });
  }

  render() {
    return (
      <div className="component-hamburger-nav header-component">
        <div className="component-container">
            <div className="component-navigation">
                <button className="hamburger hamburger--elastic js-nav-toggle is-active" type="button">
                    <span className="hamburger-box">
                      <span className="hamburger-inner"></span>
                    </span>
                  </button> 
                  <Nav />                 
              </div>
              <div className="component-nav-icon">            
                <button className="hamburger hamburger--elastic js-nav-toggle" type="button">
                    <span className="hamburger-box">
                      <span className="hamburger-inner"></span>
                    </span>
                  </button>
              </div>
          </div>
        </div>
    )
  }
}

export default Header