import React, { Component} from 'react'
import axios from 'axios';
import FadeInSection from '../components/FadeIn';
import './Skills.scss';

export class Skills extends Component {
    state = {
        skillPage: [],
        isLoaded: false
    }

    componentDidMount() {
        axios.get('https://api.samuelhadsall.com/wp-json/wp/v2/skillpage')
        .then(res => this.setState( {
            skillPage: res.data,
            isLoaded: true
        }))
        .catch(err => console.log(err));
    }

  render() {
    const { skillPage, isLoaded } = this.state;    

    if (isLoaded) {
        console.log(skillPage);
    
        return (
            <div className="component component-section skills-component">
                <div className="component-section-container">
                    <FadeInSection>
                        <div className="component-secton-intro fadeup fade-delay">
                            <h2>{ skillPage.title.rendered }</h2>            
                            <div dangerouslySetInnerHTML={{ __html: skillPage.content.rendered }}></div>
                            <div className="skill-groups">
                                { skillPage.skills_list.map((skills, index) => (
                                    <div className="skill-group-item" key={`group_${index}`}>
                                        <h3>{ skills.group_label}</h3>   
                                        <ul className="skills-group">
                                            { skills.group_list.map((list, index) => (                                                
                                                <li key={`list_${index}`}>{ list }</li>
                                            )) }
                                        </ul>
                                    </div>
                                )) } 
                            </div>
                        </div>
                    </FadeInSection>
                </div>
            </div>
        )
    }
  }
}

export default Skills
