import React, { Component } from 'react';
import axios from 'axios';
import FadeInSection from '../components/FadeIn';
import './About.scss';

export class About extends Component {
  state = {
    aboutPage: [],
    isLoaded: false
  }

componentDidMount() {
    axios.get('https://api.samuelhadsall.com/wp-json/wp/v2/aboutpage')
      .then(res => this.setState( {
          aboutPage: res.data,
          isLoaded: true
      }))
      .catch(err => console.log(err));
}
  render() {
    const { aboutPage, isLoaded } = this.state; 
    if (isLoaded) {  
      return (       
        <div className="component component-section about-component">
          <div className="component-section-container">
            <FadeInSection>
              <div className="component-secton-intro fadeup fade-delay">
                  <h2 dangerouslySetInnerHTML={{ __html: aboutPage.title.rendered }}></h2>            
                  <div dangerouslySetInnerHTML={{ __html: aboutPage.content.rendered }}></div>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="component-section-image fadeup fade-delay">            
                  <img
                  alt=""
                  src={ aboutPage.fimg_url.large.src }
                  srcSet={ aboutPage.fimg_url.large.srcset }
                  sizes={`(max-width: ${
                  aboutPage.fimg_url.large.width }px) 100vw,
                  ${ aboutPage.fimg_url.large.width }px`}                  
                  />
              </div>
            </FadeInSection>
          </div>
      </div>
      )
    }
  }
}

export default About
