import { React, Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter as Router, Outlet, Routes, Route} from 'react-router-dom';
import Settings from './components/Settings';
import App from './App';
import Header from './layouts/Header';
import SkillPage from './routes/Skills';
import AboutPage from './routes/About';
import reportWebVitals from './reportWebVitals';
import Particles from './components/Particle';
const root = ReactDOM.createRoot(document.getElementById('app'));

root.render(    
  <Fragment>  
    <Settings />   
    <Router>
      <Header />
      <Outlet />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="skills" element={<SkillPage />} /> 
        <Route path="about" element={<AboutPage />} />          
      </Routes>
    </Router>
    <Particles />   
  </Fragment>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
