import React, { useState, useEffect, useRef } from "react";

export const FadeInSection = props => {
  const isVisible = useState(false);
  const documentRef = useRef();

  useEffect(() => {
    console.log(1);   

    const observerOptions = {
        root: null,
        threshold: 0,
        rootMargin: '0px 0px -50px 0px'
    };                    
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('in-view');
                observer.unobserve(entry.target);
            }
        });
    }, observerOptions);  

    const components = Array.from(document.getElementsByClassName('component'));    

    for (let component of components) {
        const children = component.getElementsByClassName('fade-delay');

        for (let count = 0; count < children.length; count++) {
            children[count].setAttribute('style', 'transition-delay: ' + count * 0.5 + 's');
        }
        observer.observe(documentRef.current);
    }   
  }, []);

  return (
    <div
      ref={documentRef}
      className={`component fade-in-section ${isVisible ? "is-visible" : ""}`}
    >
      {props.children}
    </div>
  );
};

export default FadeInSection
