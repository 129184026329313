import React, { Fragment } from 'react'
import FrontPage from "./routes/Front";

import './App.css';

function App() {  
  return (
    <Fragment>
      <FrontPage />      
    </Fragment>
  );
}

export default App;
