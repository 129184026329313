import React, { Component } from 'react';
import { Helmet } from "react-helmet";

export class Settings extends Component {
   
      
  render() {       
    return (
        <Helmet>
            <title>Samuel Hadsall | Front-End/UI-UX Developer | Fullstack</title>
            <meta name="description" content="I build things for the web." />
        </Helmet>
    )
  }
}

export default Settings
