import React, { Component } from 'react'
import axios from 'axios';
import FadeInSection from '../components/FadeIn';
import './Front.scss';



export default class index extends Component {
    state = {
        frontPage: [],
        isLoaded: false
    }
    componentDidMount() {
        axios.get('https://api.samuelhadsall.com/wp-json/wp/v2/frontpage')
        .then(res => this.setState( {
            frontPage: res.data,
            isLoaded: true
        }))
        .catch(err => console.log(err));
    }

    render() {        
        const { frontPage, isLoaded } = this.state;   
        if (isLoaded) {  
            return (   
                <div className="component-banner-container">
                    <FadeInSection>
                        <div className="component-intro fadeup fade-delay">
                            <h1>{ frontPage.acf.banner.h1 }</h1>
                            <h2>{ frontPage.acf.banner.h2 }</h2>           
                            <div dangerouslySetInnerHTML={{ __html: frontPage.acf.banner.intro }}></div>
                        </div>
                    </FadeInSection>
                    <FadeInSection>
                        <div className="component-banner-image fadeup fade-delay">                        
                            <img
                                alt=""
                                src={frontPage.acf.banner.my_photo.url }
                                srcSet={`${frontPage.acf.banner.my_photo.sizes.medium} ${frontPage.acf.banner.my_photo.sizes['medium-width']}w,
                                ${frontPage.acf.banner.my_photo.sizes.large} ${frontPage.acf.banner.my_photo.sizes['large-width'] }w`}
                                sizes={`(max-width: ${frontPage.acf.banner.my_photo.sizes['large-width'] }px) 100vw,
                                ${frontPage.acf.banner.my_photo.sizes['large-width']}px`}                            
                                />
                        </div>
                    </FadeInSection>
                </div>
            )           
        }

        return <h3>loading....</h3>;
    }
}
