import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faCode, faUser } from "@fortawesome/free-solid-svg-icons";

export class Nav extends Component {
  render() {
    return (
        <nav>                      
            <Link to="/"><FontAwesomeIcon icon={ faHome } /> Home</Link>
            <Link to="/skills"><FontAwesomeIcon icon={ faCode }/> Skills</Link>
            <Link to="/about"><FontAwesomeIcon icon={ faUser }/> About</Link>
        </nav>
    )
  }
}

export default Nav
